<template>
  <page-list>
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline=true>
        <el-form-item label="手机号" prop="mobile">
          <el-input
              v-model="form.mobile"
              placeholder="请输入"
              maxLength="13"
            >
            </el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input
              v-model="form.email"
              placeholder="请输入"
              maxLength="100"
            >
            </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      @sort-change="sortChangeHandle"
      @row-dblclick="editHandle"
    >
      <el-table-column
        prop="id"
        label="ID"
        min-width="70"
        header-align="center"
        align="center"
        fixed="left"
      >
      </el-table-column>
      <el-table-column
        prop="groupId"
        label="角色组"
        header-align="center"
        align="center"
        min-width="80"
        :formatter="(row) => CUSTOMER_GROUP[row.groupId + '']"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
        header-align="center"
        align="center"
        min-width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="nickname"
        label="昵称"
        header-align="center"
        align="center"
        min-width="120"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="email"
        label="电子邮箱"
        header-align="center"
        align="center"
        min-width="170px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        header-align="center"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="avatar"
        label="头像"
        header-align="center"
        align="center"
        min-width="80px"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <el-image
            v-if="scope.row.avatar && scope.row.avatar.indexOf('data') !== 0"
            style="width: 30px; height: 30px"
            :src="scope.row.avatar.split(',')[0]"
            :preview-src-list="scope.row.avatar.split(',')"
            preview-teleported
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="level"
        label="等级"
        header-align="center"
        align="center"
        min-width="60px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="score"
        label="积分"
        header-align="center"
        align="center"
        min-width="60px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="logintime"
        label="登录时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.logintime).format('yyyy-MM-DD HH:mm:ss')
        "
        show-overflow-tooltip
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="loginip"
        label="登录IP"
        header-align="center"
        align="center"
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        prop="jointime"
        label="加入时间"
        header-align="center"
        align="center"
        min-width="150px"
        :formatter="
          (row) => $moment.unix(row.jointime).format('yyyy-MM-DD HH:mm:ss')
        "
        sortable="custom"
      >
      </el-table-column>
      <el-table-column
        prop="joinip"
        label="加入IP"
        header-align="center"
        align="center"
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        header-align="center"
        align="center"
        min-width="70px"
      >
        <template v-slot="scope">
          <span
            class="fa fa-circle"
            :style="{ color: USER_STATUS[scope.row.status].color }"
            >{{ " " + USER_STATUS[scope.row.status].label }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        header-align="center"
        align="center"
        fixed="right"
        width="120"
      >
        <template v-slot="scope">
          <el-button
            class="icon-btn distribute-btn fa fa-ticket"
            @click="distributeHandle(scope.row)"
            >发券</el-button
          >
          <el-button
            class="icon-btn fa fa-pencil"
            type="primary"
            @click="editHandle(scope.row)"
          >
            <!-- 编辑 -->
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-model="paginationData"
      :total="total"
      @change="getList"
    ></pagination>

    <EditDialog
      v-model="dialogVisible"
      @refresh="getList"
      :editData="editData"
      :mode="editMode"
    ></EditDialog>

    <Distribute v-model="distributeVisible" :userData="userData"></Distribute>
  </page-list>
</template>

<script>
import { USER_STATUS, CUSTOMER_GROUP } from "@/helpers/constants";
import Distribute from "./distribute.vue";
import EditDialog from "./editDialog";
import { getCustomerList,listByPage } from "@/apis/user/customer";
import { msgErr, msgSuc } from "@/helpers/message";
import { cloneDeep } from "lodash";

export default {
  components: { EditDialog, Distribute },
  data() {
    return {
      USER_STATUS: USER_STATUS,
      CUSTOMER_GROUP: CUSTOMER_GROUP,
      tableData: [],
      paginationData: {},
      initForm: {
        sort: "user.id",
        order: "desc",
      },
      form: {},
      total: 0,
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
      distributeVisible: false,
      userData: {},
    };
  },
  created() {},
  mounted() {},
  activated() {
    this.form = cloneDeep(this.initForm);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      listByPage({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    dateFormatter(row) {
      return row.logintime
        ? this.$moment.unix(row.logintime).format("yyyy-MM-DD HH:mm:ss")
        : "无";
    },

    distributeHandle(row) {
      this.distributeVisible = true;
      this.userData = row;
    },

    sortChangeHandle({ prop, order }) {
      if (prop && order) {
        this.form = {
          sort: prop,
          order: order === "descending" ? "desc" : "asc",
        };
      } else {
        this.form = cloneDeep(this.initForm);
      }
      this.getList();
    },

    editHandle(row) {
      this.editMode = "edit";
      this.dialogVisible = true;
      this.editData = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.distribute-btn {
  background-color: #3498db;
  color: #ffffff;
  width: 60px;
}
</style>
